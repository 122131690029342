import { getCookie } from '@cookies'
import { trackFormSubmission } from '@helpers'
import { makeStyles } from '@material-ui/styles'

export function mktoTrialSignup(form, location = 'us') {
  const UX1Countries = ['US', 'MX', 'IN']
  const changeEvent = new Event('change')
  const regionDisplayNames = new Intl.DisplayNames(['en'], { type: 'region' })
  const geoCookieValue = getCookie('OPTOUTMULTI_GEO')
  // Get form els
  const impliedConsentMessage = document.querySelector('#impliedConsent')
  const countryInput = document.querySelector("input[name='Country']")
  // Get input vals
  const regionInputValue = document
    .getElementById('TrialUxExternal')
    .getAttribute('data-region')
  const countryInputValue = regionDisplayNames.of(geoCookieValue)

  console.log('BUILD TRIAL FORM: location: ' + location)

  if (countryInputValue) {
    countryInput.value = countryInputValue
    countryInput.dispatchEvent(changeEvent)
    if (UX1Countries.includes(geoCookieValue)) {
      impliedConsentMessage.style.visibility = 'visible'
    }
    console.log('BUILD TRIAL FORM: Detected language:' + countryInputValue)
  } else {
    const companyDetails = JSON.parse(
      window.localStorage.getItem('_6senseCompanyDetails')
    )
    impliedConsentMessage.style.visibility = 'visible'
    console.log(
      companyDetails
        ? 'BUILD TRIAL FORM: Unable to pull country'
        : 'BUILD TRIAL FORM: 6sense not found'
    )
  }

  const tosInput = document.querySelector("input[name='optinDataCollection']")
  if (tosInput) {
    const submitButton = document.querySelector("button[type='submit']")
    submitButton.disabled = true
    tosInput.addEventListener('change', (event) => {
      submitButton.disabled = !event.target.checked
    })
  }

  form.onSubmit(function () {
    const vals = form.vals()
    const dataProduct = vals.dataProduct || 'generic'
    trackFormSubmission(vals.formid, dataProduct)
  })

  form.onSuccess(function (values, _followUpUrl) {
    const form = document.createElement('form')
    form.setAttribute(
      'action',
      'https://acc-trials.autodesk.com/trial-signup/i/lookup/index.php'
    )
    form.setAttribute('method', 'POST')
    form.setAttribute('id', 'dataHiddenForm')

    const input1 = document.createElement('input')
    input1.type = 'hidden'
    input1.name = 'email'
    input1.value = values.Email
    form.appendChild(input1)

    const input2 = document.createElement('input')
    input2.type = 'hidden'
    input2.name = 'company'
    input2.value = values.Company
    form.appendChild(input2)

    const input3 = document.createElement('input')
    input3.type = 'hidden'
    input3.name = 'phone'
    input3.value = values.Phone
    form.appendChild(input3)
    if (regionInputValue) {
      const input4 = document.createElement('input')
      input4.type = 'hidden'
      input4.name = 'region'
      input4.value = regionInputValue
      form.appendChild(input4)
    }
    document.body.appendChild(form)
    document.getElementById('dataHiddenForm').submit()

    return false
  })
}

export const mktoTrialSignupStyles = makeStyles((theme) => ({
  form: {
    '& .mktoForm': {
      color: 'inherit !important',
      fontFamily: 'inherit !important',
      width: '100% !important',
      '& input': {
        fontSize: 'inherit !important',
      },
    },
    '& a': {
      borderBottom: `1px dashed ${theme.palette.text.matterhorn}`,
      color: theme.palette.text.matterhorn,
      display: 'inline',
      padding: '0 !important',
      textDecoration: 'none',
      textTransform: 'none',
    },
    '& .mktoFormRow': {
      '&:nth-of-type(2)': {
        display: 'none',
      },
      '& .mktoFormCol': {
        marginBottom: '16px !important',
        minHeight: 'unset !important',
        width: '100%',
      },
    },
    '& .mktoFormRow.fullWidthRow': { width: '100%' },
    '& .mktoLabel': {
      color: theme.palette.text.font,
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: '10px',
      width: '100% !important',
    },
    '& .mktoOffset': { display: 'none !important' },
    '& .mktoGutter': { display: 'none !important' },
    '& .mktoAsterix': { display: 'none !important' },
    '& #LbloptinMarketingCommunications': { display: 'none' },
    '& #LbloptinDataCollection': { display: 'none' },
    '& .mktoFieldWrap': {
      display: 'flex',
      flexDirection: 'column',
      float: 'none !important',
    },
    '& .mktoTextField': {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
      height: '38px !important',
      padding: '0px 14px !important',
      width: '100% !important',
      '&:focus': {
        backgroundColor: 'none',
        boxShadow: `0px 0px 0px 1px ${theme.palette.primary.main}`,
        outline: 'none',
      },
    },
    '& .mktoCheckboxList': {
      fontSize: '13px',
      padding: '0 !important',
      width: '100% !important',
      '& input': {
        marginTop: '2px',
        float: 'left',
      },
      '& label': {
        lineHeight: 'inherit',
        marginBottom: '0 !important',
      },
    },
    '& .mktoButtonRow': {
      display: 'block',
      '& .mktoButtonWrap': {
        display: 'block',
        margin: '4px 0 16px 0 !important',
        '& button': {
          backgroundColor: `${theme.palette.primary.main} !important`,
          backgroundImage: 'none !important',
          border: 'none !important',
          borderRadius: '4px',
          color: `theme.palette.primary.contrastText !imporant`,
          fontSize: '15px !important',
          fontWeight: '700',
          letterSpacing: '-0.18px',
          minHeight: '45px',
          minWidth: '150px',
          padding: '8px 12px',
          textTransform: 'uppercase',
          width: '100%',
        },
      },
    },
  },
}))
